<template>
    <div class="flex_layout">
         <div class="main_title">Administrator</div>
         <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-input v-model:value="query.username" placeholder="Username" />
                    </div>
                    <div class="query_box">
                        <a-space>
                            <a-button @click="getAdminListHandler">
                              <template #icon><component :is="$antIcons['SearchOutlined']" /></template>
                            </a-button>
                            <a-button class="primary btn" @click="update('add')">Add</a-button>
                        </a-space>
                    </div>
                </a-space>
            </div>
        </div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
              <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                  <template #role="{ record }">
                      <span>{{getRole(record.role_id)}}</span>
                  </template>
                  <template #state="{ record }">
                      <span>{{getState(record.state)}}</span>
                  </template>
                  <template #action="{ record }">
                       <a-space>
                           <a-button class="primary btn" @click="update('edit', record)" size="small" type="primary">Edit</a-button>
                           <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.admin_id)"
                            >
                               <a-button danger size="small">Delete</a-button>
                           </a-popconfirm>
                       </a-space>
                   </template>
              </a-table>
            </a-spin>
        </div>
        <div class="flex_bottom">
            <a-pagination size="small" v-model:current="query.page" :total="total" show-less-items />
        </div>
        <a-drawer
            title="Administrator"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
        >
            <div class="container">
                <a-spin :spinning="spinning">
                    <div class="form">
                        <div class="form_title">Administrator</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Username">
                               <a-input v-model:value="formData.username" placeholder="Username" />
                            </a-form-item>
                            <a-form-item label="Password">
                               <a-input v-model:value="formData.password" placeholder="Password" type="password" />
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span:20, offset: 4 }">
                                <a-button block type="primary" class="primary btn" @click="create">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-spin>
            </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'UUID',
        dataIndex: 'uuid',
        key: 'uuid  ',
    },
    {
        title: 'Role name',
        slots: { customRender: 'role' },
    },
    {
        title: 'State',
        slots: { customRender: 'state' },
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { message } from 'ant-design-vue'
import { getAdminList, createAdmin, removeAdmin } from '@/apis/admin'
export default {
    name: 'Product',
    data(){
        return {
            dataSource: [],
            columns,
            query: {
                username: '',
                page: 1,
                page_size: 10
            },
            formData: {},
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            spinning: false,
            total: 1,
            visible: false,

        }
    },
    methods: {
        update(actionType, adminInfo){
            if(actionType == 'add'){
                this.initParams()
            }
            if(actionType == 'edit'){
                this.formData = adminInfo
            }
            this.formData.action_type = actionType
            this.visible = true
        },
        async remove(admin_id){
            this.spinning = true
            const res = await removeAdmin({admin_id})
            this.spinning = false
            if(res){
                this.getAdminListHandler()
            } 
        },
        async create(){
            if(this.formData.username == '') return message.error('Please input username')
            if(this.formData.password == '') return message.error('Please input password')
            this.spinning = true
            const res = await createAdmin(this.formData)
            this.spinning = false
            if(res){
                this.onClose()
                this.getAdminListHandler()
            } 
        },
        onClose(){
            this.visible = false
        },
        async getAdminListHandler(){
            this.spinning = true
            const res = await getAdminList(this.query)
            this.spinning = false
            if(res){
                this.dataSource = res.list.rows
                this.total = res.list.count
            }
        },
        initParams(){
            this.formData = {
                admin_id: 0,
                username: '',
                password: '',
                action_type: 'add'
            }
        }
    },
    computed: {
        getRole(){
            return role_id => {
                if(role_id == 0) return 'Super administrator'
            }
        },
        getState(){
            return state => {
                if(state == 0) return 'Normal'
            }
        }
    },
    created(){
        this.initParams()
        this.getAdminListHandler()
    }
}
</script>
<style lang="stylus" scoped>
</style>